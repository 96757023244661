@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #1E2749;
  --secondary: #273469;
  --accents: #E4D9FF;
  --text1: #EBEBFF;
  --test: #353946;
}

* {

  color: var(--text1);
  font-family: 'Playfair Display', serif;
  font-size: 36px;
}

html{
  background-color: var(--primary);
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

svg {
  fill: #E4D9FF99
}

svg:hover {
  fill: var(--accents);
}

main {
  padding-bottom: 80px;
}
header {
  margin: 16px;
  display: flex;
}

h2 {
  border-bottom: 1px solid var(--accents);
  color: #FFAE42;
  text-align: center;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 64px;
}

h1{
color: #FFAE42;
}

li {
  list-style: none;
  margin: auto 36px;
  border-bottom: 1px solid var(--accents);
  background-image: linear-gradient(to top, var(--accents) 50%, transparent 50%);
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.5s ease-in-out,
              color 1s ease;
  color: var(--text1);
}

li:hover{
  background-position: bottom;
  color: var(--primary);
  cursor: pointer;
}

ul {
  display: flex;
  margin-left: auto;
  margin-right: 0;
  margin-top: 24px;
}

footer{
  display: flex;
  position: fixed;
  justify-content: center;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: var(--primary);
}

a {
  text-decoration: none;
}

p{
  margin: 8px;
}

textarea {
  height: 300px;
  margin-top: 16px;
}

input {
height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--primary) inset !important;
    box-shadow: 0 0 0 30px var(--primary) inset !important;
    -webkit-text-fill-color: var(--text1);
}

textarea, input {
  border: none;
  overflow: auto;
  outline: none;
  resize: none; 
  background-color: var(--primary);
  border: 1px solid var(--text1);
  border-radius: 8px;
  width: 80%;
  font-size: 18px;
}

button {
  all: unset;
  border: 1px solid var(--accents);
  color: var(--text1);
  background-color: var(--primary);
  transition: color 1s ease, 
              background-color .75s ease;
  width: 5rem;
  text-align: center;
 
}

button:hover {
  color: var(--primary);
  background-color: var(--accents);
  cursor: pointer;
  background-position: bottom;
}

.project {
  width: 80%;
  border-radius: 8px;
  margin: auto;
  margin-left: 8%;
  display: flex;
  margin-bottom: 48px;
}

.projectText {
width: 100%;
margin: auto;
}

.projectImg {
  display: flex;
  margin: auto;
  
}
.projectImg2 {
  width: 100%;
  height: auto;
  margin:auto;
  opacity: .3;
  transition: opacity .75s ease-in-out;
  border-radius: 8px;
}

.projectImg2:hover {
  opacity: .85;
  cursor: pointer;
}

.footerImg{
  display: flex;
  margin: 16px 32px;
  
}

.callCard {
  margin: 24px;
  margin-top: 8px;
  font-size: 48px;
  color: #FFAE42
}

.aboutHead{
  display: flex;
  align-items: flex-start;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  width: 45%;
  align-items: left;
  justify-content: center;
}

.fullName{
  margin-left: 52px;
}

.firstName{
  margin-bottom: 0px;
  margin-top: 48px;
  font-size: 84px;
  font-weight: bold;
  color: #FFAE42;
}

.lastName{
  margin-top: 0px;
  font-size: 84px;
  font-weight: bold;
  color: #FFAE42;
}

.aboutText {
  width:45%;
  text-align: left;
  margin: auto;
  overflow-y: visible;
}

.navActive{
  background-color: var(--accents);
  color: var(--primary);
}

.error-text {
  font-size: 24px;
  margin-bottom: 20px;
}

.techUsed{
  color: #FFAE42;
}

#headshot {
  margin: auto 0px;
  width: 350px;
  border-radius: 50%;
}

#resumeNav{
  color: var(--text1);
  transition: color 1s ease;
}

#resumeNav:hover{
  color: var(--primary);
}

#contact {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 200%;
  text-align: center;
}

#contact-title{
  font-size: 60px;
}


@media screen and (max-width: 1000px) {
  header {
    margin: 16px;
    display: flex;
    flex-direction: column;
  }

  ul {
    flex-direction: column;
    margin: auto;
  }

  li{
    margin: 8px 36px;
  }

  .aboutHead{
    display: flex;
    flex-direction: column;
    
    align-items: flex-start;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    width: 45%;
    align-items: left;
    justify-content: center;
  }

  .fullName{
    text-align: center;
    margin: auto;
  }

  .firstName{
    font-size: 64px;
  }

  .lastName{
    font-size: 64px;
  }

  .aboutText {
    text-align: center;
    width:80%;
  }

  #headshot{
    justify-content: center;
    margin: auto;
  }

  #contact {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  *{
    font-size: 24px;
  }

  .firstName{
    font-size: 36px;
  }

  .lastName{
    font-size: 36px;
  }

  .aboutText {
    width:80%;
  }

  .callingCard{
    margin: auto;
    width: auto;
  }

  #contact {
    width: 80%;
  }

  h2 {
    font-size: 48px;
  }
}



